import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faPlusCircle, faSort, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { UserService } from 'app/core/services/admin/user/user.service';
import { LayoutService } from 'app/core/services/global/layout/layout.service';
import { SwalModalService } from 'app/core/services/global/modal/modal.service';
import { TableService } from 'app/core/services/global/table/table.service';
import { ToastService } from 'app/core/services/global/toast/toast.service';
import { VariableProjectService } from 'app/core/services/project/project/variable.service';
import { ActionButtonsDef, ActionEvent, ActionType } from 'app/shared/components/action-buttons';
import { ACL } from 'app/shared/models/acl';
import { fieldTypes } from "app/shared/models/field-type.enum";
import { LazyLoadDataModel } from "app/shared/models/lazy-load-data";
import { FilterMetadata, LazyLoadEvent } from "primeng-lts/api";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {Language} from "app/shared/models/language";
import {LanguageService} from "app/core/services/admin/language/language.service";

@Component({
  selector: 'app-variable',
  templateUrl: './variable-list.component.html',
  styleUrls: ['./variable-list.component.scss']
})
export class VariableListComponent implements OnInit, OnDestroy{

  private _variables: any;
  formArray: FormArray;
  formVariable: FormGroup;
  private _acl: ACL;
  private _actionButtonSub: Subscription;

  readonly actions: ActionButtonsDef = [
    { type: ActionType.Update, right: "MAESTRO_PROJECT_PROJECTS_UPDATE", operation: "update" },
    { type: ActionType.Delete, right: "MAESTRO_PROJECT_PROJECTS_DELETE", operation: "delete" },
];

@ViewChild("newVariable") private newVariable: SwalComponent;

  readonly faSave = faSave;
  readonly faSort = faSort;
  readonly faPlus = faPlusCircle;
  readonly faTrash = faTrashAlt;
  readonly faEdit = faEdit;
  readonly faClose = faTimes;

  fieldTypes = [];

  tableRows: any[] = [];
  tableColumns: any[] = [];
  totalRecords: number = 0;
  recordsFiltered: number = 0;
  currentDatatableFilters: LazyLoadEvent = {}; // Use to reload data when filter with tag/element type & action like duplicate/delete
  globalFilterFields: string[] = []; // Define which columns can be use to search data
  selectColumns: boolean = true; // Display a multiselect to show/hide columns
  _selectedColumns: any[] = []; // Define which columns are selected

  allFilters = {
    filterExportTypes: {
        data: [],
        cascadeSelect: false,
        field: "",
    },
    filterCategorie: {
        data: [],
        cascadeSelect: false,
        field: "",
    },
  };

  currentCustomFilters: any = {}; // Use to reload with the right filters
  shortPaginatorIsFirstPage: boolean = true;
  shortPaginatorIsLastPage: boolean = false;

  strictMode: boolean = false; // strict value given by datatable
  searchOnFieldValue: boolean = false; // search on export value given by datatable
  searchOnArchived: boolean = false; // Use in project to display archived projects

  private _ngUnsubscribe: Subject<void>;

  selectedLanguage : Language;
  basicLanguage : Language;

  constructor(
    private _layout: LayoutService,
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _toaster: ToastService,
    private _translate: TranslateService,
    private _variableService: VariableProjectService,
    private _tableService: TableService,
    private _userService: UserService,
    private _modal: SwalModalService,
    private _languageService: LanguageService
    ) {
      this._ngUnsubscribe = new Subject();
    }

  ngOnInit(): void {

    fieldTypes.forEach(fieldType => {
      this.fieldTypes.push(fieldType);
    });
    this.fieldTypes.push("list multiple");
    // this._variables = this._route.snapshot.data.variables;

    this._initData();

    this._languageService.selectedLanguage$.subscribe((language) => {
      this.selectedLanguage = language;
    });

    this.basicLanguage = this._languageService.getBasicLanguage();
  }

  ngOnDestroy(){
    this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        if (this._actionButtonSub) {
          this._actionButtonSub.unsubscribe();
      }
  }

  /**
     * Init the data
     */
  private _initData(): void {

    this._acl = this._userService.getUserAclFromToken();
    this.formArray = this._fb.array([], this.uniqueValues());

    this._layout.actionButton.enable = this._acl.MAESTRO_PROJECT_PROJECTS_CREATE ? true : false;
        this._layout.actionButton.title = "project.variables.create";
        this._actionButtonSub = this._layout.actionButton.click$.subscribe((_) => this.onCreateButtonClick());

    // this._variables.data.forEach((f) => {
    //   const formGroup = this._fb.group({
    //     id: [f.id, Validators.required],
    //     name: [f.name, Validators.required],
    //     categorie: [{ value: f.categorie, disabled: true }, Validators.required],
    //     fieldType: [{ value: f.fieldType, disabled: true }, Validators.required],
    //   });


    // formGroup.controls.categorie.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((value) => {
    //   const control = formGroup.controls.fieldType;
    //   value != 'media' ? control.patchValue("") : control.patchValue(-1);
    // });
    //   this.formArray.push(formGroup);
    // });
  }

  /**
     * Check if each value is unique
     * @returns
     */
  uniqueValues(): ValidatorFn {
    return (formArray: FormArray): null => {
      const values = formArray.value.map((v) => v.name.toLowerCase());
      formArray.controls.forEach((formGroup: FormGroup) => {
          const control = formGroup.controls.name;
          if (values.filter((v: string) => v.trim().toLowerCase() === control.value.trim().toLowerCase()).length > 1 && control.value) {
              control.setErrors({ uniqueValue: true });
          } else {
              const errors = control.errors;
              if (errors && errors.uniqueValue) {
                  delete errors.uniqueValue;
              }
              if (errors && Object.keys(errors).length === 0) {
                  control.setErrors(null);
              } else {
                  control.setErrors(errors);
              }
          }
      });
      return null;
    };
  }

  onCreateButtonClick() {
    this.formVariable = this._fb.group({
      id: [null],
      name: [[], Validators.required],
      nameTranslations: [[]],
      categorie: [[], Validators.required],
      fieldType: [[], Validators.required],
    })

    this.formVariable.controls.categorie.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((value) => {
      const control = this.formVariable.controls.fieldType;
      value != 'media' ? control.patchValue("") : control.patchValue(-1);
    });
    this.newVariable.fire();
  }

  OnUpdatebuttonClick(variableId) {
    let variable = this.tableRows.find(row => row.id == variableId);

    this.formVariable = this._fb.group({
      id: [variable.id],
      name: [variable.name, Validators.required],
      nameTranslations: [variable.nameTranslations],
      categorie: [{value: variable.categorieValue, disabled: variable.isUse}, Validators.required],
      fieldType: [{value: variable.fieldTypeValue, disabled: variable.isUse}, Validators.required],
    })

    this.formVariable.controls.categorie.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((value) => {
      const control = this.formVariable.controls.fieldType;
      value != 'media' ? control.patchValue("") : control.patchValue(-1);
  });
    this.newVariable.fire();
  }



  /**
     * Add a new value
     */
  addField(): void {
    const fG = this._fb.group({
        id: [-1],
        name: ["", Validators.required],
        nameTranslations: [[]],
        categorie: ["", Validators.required],
        fieldType: ["", Validators.required],
    });

    fG.controls.categorie.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((value) => {
      const control = fG.controls.fieldType;
      value != 'media' ? control.patchValue("") : control.patchValue(-1);
    });

    this.formArray.push(fG), this.formArray.markAsDirty();
    this.formArray.updateValueAndValidity();
  }

  /**
     * Save the list
     */
  saveList(): void {
    if (this.formVariable.invalid) {
        return;
    }

    const nameTranslations = this.formVariable.value.nameTranslations || {};
    nameTranslations[this.selectedLanguage.internationalCode] = this.formVariable.value.name;
    this.formVariable.controls.nameTranslations.setValue(nameTranslations);
    this.formVariable.controls.name.setValue(nameTranslations[this.basicLanguage.internationalCode]);

    this._variableService
        .create(this.formVariable.value)
        .subscribe(() => {
            this.formVariable.markAsPristine();
            this._toaster.show({
                message: this._translate.instant("general.saved"),
                type: "success",
            });
            this.loadList(this.currentDatatableFilters);
        });
  }

    /**
     * Delete a value
     * @param index
     */
    deleteField(index: number): void {
      // this.saveList();
      this._variableService.deleteVariable(index).subscribe(() => {
        this.loadList(this.currentDatatableFilters);
      })
    }

    /**
     * Load data displayed in datatable
     * @param event
     */
    loadList(event: LazyLoadEvent): void {
      if (undefined !== event.filters) {
          this.setCustomFilters(event.filters);
      }

      const requestFilters: LazyLoadDataModel = {
          lazyLoadFilters: event,
          customFilters: this.currentCustomFilters,
          strictMode: this.strictMode,
          searchOnFieldValue: this.searchOnFieldValue,
          searchOnArchived: this.searchOnArchived,
      };

      this._variableService.getDatatableVariables(JSON.stringify(requestFilters)).subscribe((resp) => {
          const data = resp.data;

          this.allFilters.filterCategorie.data = data.allExportTypes;

          const rows = this._initRows(data.rows);

          this.tableRows = Object.values(rows);

          this.translateCategirieAndFieldType();
          this.tableColumns = Object.values(data.columns);
          this.totalRecords = data.recordsTotal;
          this.recordsFiltered = data.recordsFiltered;
          this.currentDatatableFilters = data.datatableFilters;
          this.setGlobalFilterFields();
          this._selectedColumns = this.tableColumns;
          this.shortPaginatorIsFirstPage = this.currentDatatableFilters.first === 0;
          this.shortPaginatorIsLastPage = this.currentDatatableFilters.first === this.recordsFiltered - this.tableRows.length;
      });
  }

  /**
     * Set custom filters formatted for request send to back
     * @param eventFilters
     */
  setCustomFilters(eventFilters: FilterMetadata): void {
    let customFilters = {};

    if (Object.keys(eventFilters).length) {
      Object.entries(eventFilters).map(([key, value]) => {
        if (this.allFilters.hasOwnProperty(key) && this.allFilters[key].field) {
          let ids = [];
          let subVal = value[0].value;

          if (null !== subVal) {
              ids = subVal.map((d) => d.id);
          }

          customFilters[this.allFilters[key].field] = ids;
        }
      });
    }

    this.currentCustomFilters = customFilters;
  }

  private _initRows(dataRows: any) {
    let rows = [...dataRows];

    return rows;
  }

  /**
     * Set global filters from LazyLoadEvent data
     */
  setGlobalFilterFields(): void {
    this.tableColumns.forEach((col) => {
        if (col.hasOwnProperty("field")) {
          if (col.hasOwnProperty("globalFilter") && col.globalFilter) {
              this.globalFilterFields.push(col.field);
          }

          if (this.selectColumns && col.field) {
              col.header = this._tableService.translateColumns(col); // Add columns header to display in columns selector
          }

          if (col.hasOwnProperty("filterList") && null !== col.filterList && col.hasOwnProperty("filterField") && null !== col.filterField && this.allFilters.hasOwnProperty(col.filterList)) {
              this.allFilters[col.filterList].field = col.filterField;
          }
        }
    });
  }

  onActionClick(event: ActionEvent) {
    const id = event.rowId;

    switch (event.action) {
        case ActionType.Delete:
            this.deleteField(id);
            break;
        case ActionType.Update:
            this.OnUpdatebuttonClick(id);
    }
  }

  shortPaginatorPrev(): void {
    this.currentDatatableFilters.first = this.currentDatatableFilters.first - this.currentDatatableFilters.rows;
    this.loadList(this.currentDatatableFilters);
  }

  shortPaginatorReset(): void {
    this.currentDatatableFilters.first = 0;
    this.loadList(this.currentDatatableFilters);
  }

  shortPaginatorNext(): void {
    this.currentDatatableFilters.first = this.currentDatatableFilters.first + this.currentDatatableFilters.rows;
    this.loadList(this.currentDatatableFilters);
  }

  changeAdvancedSearch(event: any): void {
    if (this.hasOwnProperty(event.advancedSearchParam)) {
        this[event.advancedSearchParam] = event.advancedSearchValue;
    }
  }

  translateCategirieAndFieldType( )
  {

    for (let index = 0; index < this.tableRows.length; index++) {
      this.tableRows[index].categorieValue = this.tableRows[index].categorie;
      this.tableRows[index].fieldTypeValue = this.tableRows[index].fieldType;
      this.tableRows[index].categorie = this._translate.instant("dataview."+this.tableRows[index].categorie);
      if(this.tableRows[index].fieldType == -1){

        this.tableRows[index].fieldType = this.tableRows[index].categorie == this._translate.instant("dataview.media") ? this._translate.instant("dataview.medias") : "";
      } else {
        switch (this.tableRows[index].fieldType) {
          case "date":
            this.tableRows[index].fieldType = this._translate.instant("table.date");
            break;
          case "table":
            this.tableRows[index].fieldType = this._translate.instant("general.table.name");
            break;
          case "fieldset":
            this.tableRows[index].fieldType = this._translate.instant("pim.element.fieldset");
            break;
          default:
            this.tableRows[index].fieldType = this._translate.instant("general."+this.tableRows[index].fieldType);
            break;
        }
      }

    }

    for (let index = 0; index < this.allFilters.filterCategorie.data.length; index++) {
      this.allFilters.filterCategorie.data[index].name = this._translate.instant("dataview."+this.allFilters.filterCategorie.data[index].name);

    }

  }

}
