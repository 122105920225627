import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Tab } from "app/shared/models";
import { Router } from '@angular/router';
import { SidebarService } from "../../..";
import { Subscription } from "rxjs";
import {Language} from "app/shared/models/language";
import {LanguageService} from "app/core/services/admin/language/language.service";

@Component({
    selector: "app-sidebar-routing-form",
    templateUrl: "./sidebar-routing-form.component.html",
    styleUrls: ["./sidebar-routing-form.component.scss"],
})
export class SidebarRoutingFormComponent implements OnInit {
    @Output() validSubmit: EventEmitter<Tab> = new EventEmitter();
    @Output() delete: EventEmitter<Tab> = new EventEmitter();
    @Input() currentMode: string;
    @Input() formDisplayed: boolean;
    @Input() aclOptions: {
        delete: number;
        edit: number;
        create: number;
    };
    private _errorSubmit: Subscription;
    controlInput:boolean;
    private _currentNametabs :Subscription;
    private _currentNameTranslations :Subscription;
    nameTabs;
    nameTranslationsTabs;
    selectedLanguage : Language;
    languagesList : Language[] = [];

    constructor(fb: FormBuilder,private sidebarService:SidebarService,private _router:Router, private _languageService: LanguageService) {
        this._errorSubmit = sidebarService.errorSubmit$.subscribe((def) =>  this.controlInput = def);
        this._currentNametabs = sidebarService.tabName$.subscribe((def) => this.nameTabs = def);
        this._currentNameTranslations = sidebarService.tabNameTranslations$.subscribe((def) => this.nameTranslationsTabs = def);

        this.tabForm = fb.group({
            [this.tabNameInput]: ["", Validators.required],
            [this.tabNameTranslations]: [{}],
        });
    }



    ngOnInit(): void {
        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
        });

        this._languageService.languageOptions$.subscribe((languages) => {
            this.languagesList = languages;
        });
    }

    readonly faPlus = faPlus;
    readonly faEdit = faEdit;
    readonly faTrashAlt = faTrashAlt;

    submitted: boolean;
    readonly tabForm: FormGroup;
    readonly tabNameInput = "tabForm";
    readonly tabNameTranslations = "tabTranslationsForm";



    onCancel() {
        this.submitted = false;
        this.sidebarService.errorSubmit = false;
        this._closeForm();
    }

    onCreate() {
        this.tabForm.get(this.tabNameInput).setValue("");
        const obj = {};
        obj[this.selectedLanguage.internationalCode] = ''
        this.tabForm.get(this.tabNameTranslations).setValue(obj);

        this._openForm();
        this._changeMode("create");
        // this._router.navigate([this.options.absoluteRoute])
    }

    onEdit() {
        if(this.tabForm.get(this.tabNameInput)){
            this.controlInput = true
        }
        this.tabForm.get(this.tabNameInput).setValue(this.nameTabs);
        this.tabForm.get(this.tabNameTranslations).setValue(this.nameTranslationsTabs);
        this._openForm();
        this._changeMode("edit");

    }

    onCreateSubmit() {
        this.submitted = true;
        if (this.tabForm.valid) {
            const nameTranslations = this.tabForm.value[this.tabNameTranslations];

            this.languagesList.forEach(language => {
                if (!nameTranslations.hasOwnProperty(language.internationalCode) || !nameTranslations[language.internationalCode]) {
                    nameTranslations[language.internationalCode] = this.tabForm.value[this.tabNameInput].trim();
                }
            })
            this.validSubmit.emit({ name: this.tabForm.value[this.tabNameInput], nameTranslations: nameTranslations });
        }
    }

    onUpdateSubmit() {
        this.submitted = true;
        if (this.tabForm.valid) {
            const nameTranslations = this.tabForm.value[this.tabNameTranslations];
            const internationalCode = this.selectedLanguage ? this.selectedLanguage.internationalCode : 'fr_FR';
            const basicLanguage = this.getBasicLanguage();

            this.languagesList.forEach(language => {
                if (!nameTranslations.hasOwnProperty(language.internationalCode) || !nameTranslations[language.internationalCode]) {
                    nameTranslations[language.internationalCode] = nameTranslations[basicLanguage.internationalCode];
                }
            })
            nameTranslations[internationalCode] = this.tabForm.value[this.tabNameInput].trim();

            this.validSubmit.emit({
                id: 69,
                name: nameTranslations[basicLanguage.internationalCode],
                nameTranslations: nameTranslations,
            });
        }
    }

    private _closeForm() {
        this.submitted = false;
        this.formDisplayed = false;
    }

    private _changeMode(mode: "create" | "edit") {
        this.submitted = false;
        this.currentMode = mode;
    }

    private _openForm() {
        this.submitted = false;
        this.formDisplayed = true;
        this.controlInput = false;
    }

    getBasicLanguage() : Language
    {
        const basicLanguage = this.languagesList.find(language => language.isBasic);

        if (basicLanguage) {
            return basicLanguage;
        }

        // Si aucune langue de base n'est trouvée, retourner une valeur par défaut
        return {
            id: 0,
            label: 'Français',
            icon: '',
            code: 'fr',
            internationalCode: 'fr_FR',
            isVisible: true,
            isBasic: true
        };
    }
}
