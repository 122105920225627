import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import { environment } from "src/environments";
import {BehaviorSubject, Observable, of} from "rxjs";
import {Language} from "app/shared/models/language";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class LanguageService  {
    readonly enable$ = new BehaviorSubject<boolean>(false);
    private _enabled;


    private _languageOptions = new BehaviorSubject<Language[]>([]);
    languageOptions$ = this._languageOptions.asObservable();

    private _allLanguages = new BehaviorSubject<Language[]>([]);
    allLanguages$ = this._allLanguages.asObservable();

    // Ajoutez un BehaviorSubject pour suivre la langue sélectionnée
    private _selectedLanguage = new BehaviorSubject<Language | null>(null);
    selectedLanguage$ = this._selectedLanguage.asObservable();

    private isToggled =  new BehaviorSubject<boolean>(false);
    isToggled$= this.isToggled.asObservable();

    constructor(private _http: HttpClient,private authService:AuthService) {
        if(this.authService.isLoggedIn()){
            this.getAll().subscribe((data) => {
                const arrayLang = data.data; // Declare as let to reassign later
                this._allLanguages.next(arrayLang);

                this.updateLangOptions(arrayLang);
            });
        }


    }

    set enable(value: boolean) {
        if (this._enabled !== value) {
            this._enabled = value;
            this.enable$.next(value);
        }
    }

    getFlag(country:string,style:string ='shiny',size: number = 24):string{
        return `https://flagsapi.com/${country.toUpperCase()}/${style}/${size}.png`
    }

    getFlagPath(country: string, style: string = 'shiny', size: number = 24): Observable<string> {
        const url = `https://flagsapi.com/${country.toUpperCase()}/${style}/${size}.png`;
        const defaultUrl = '/assets/img/default-flag.png'; // Remplacez par l'URL de votre image par défaut

        return this._http.get(url, { responseType: 'blob' }).pipe(
            map(() => url),
            catchError(() => of(defaultUrl))
        );
    }

    getImageURL(countryCode, style:string ='shiny', size: number = 24, defaultUrl = '/assets/img/default-flag.png') {
        return new Promise((resolve) => {
            const url = `https://flagsapi.com/${countryCode.toUpperCase()}/${style}/${size}.png`;
            const img = new Image();
            img.onload = () => resolve(url);
            img.onerror = () => resolve(defaultUrl);
            img.src = url;
        });
    }

    toggle() {
        const currentValue = this.isToggled.getValue();
        this.isToggled.next(!currentValue);
    }

    displayTraduction() {
        this.isToggled.next(true);
    }
    hiddenTraduction() {
        this.isToggled.next(false);
    }

    getAll(){
        return this._http.get<any>(`${environment.adminApiUrl}/list-languages.json`);
    }

    // Ajoutez une fonction pour mettre à jour la langue sélectionnée
    setSelectedLanguage(language: Language) {
        this._selectedLanguage.next(language);
    }

    updateLangOptions(arrayLang) {
        const storedLang = localStorage.getItem('contentLanguages');

        if (storedLang) {
            const storedLangArray = JSON.parse(storedLang);
            arrayLang = storedLangArray.map(internationalCode =>
                arrayLang.find(lang => lang.internationalCode === internationalCode)
            ).filter(lang => lang !== undefined);
        }

        this._languageOptions.next(arrayLang);

        // Find the default language
        const defaultLanguage = arrayLang?.[0] || arrayLang?.find(lang => lang.isBasic) || null;
        this.setSelectedLanguage(defaultLanguage);
    }

    /**
     * create a new language
     *
     * @param body
     * @returns
     */
    create(body: any): Observable<Language> {
        return this._http.post<Language>(`${environment.adminApiUrl}/create-languages.json`, body);
    }

    /**
     * Return the language's data
     *
     * @param id
     * @returns
     */
    findById(id: number): Observable<any> {
        return this._http.get<any>(`${environment.adminApiUrl}/list-languages/${id}.json`);
    }

    /**
     * Update the language's data
     *
     * @param id
     * @param body
     * @returns
     */
    update(id: number, formData: FormData): Observable<Language> {
        return this._http.post<Language>(`${environment.adminApiUrl}/update-languages/${id}.json`, formData);
    }

    /**
     * Delete a language
     *
     * @param id
     * @returns
     */
    delete(id: number): Observable<Language> {
        return this._http.delete<Language>(`${environment.adminApiUrl}/delete-languages/${id}.json`);
    }



    getBasicLanguage() : Language
    {
        const basicLanguage = this._languageOptions.getValue().find(language => language.isBasic);

        if (basicLanguage) {
            return basicLanguage;
        }

        // Si aucune langue de base n'est trouvée, retourner une valeur par défaut
        return {
            id: 0,
            label: 'Français',
            icon: '',
            code: 'fr',
            internationalCode: 'fr_FR',
            isVisible: true,
            isBasic: true
        };
    }

}
