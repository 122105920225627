import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { MaestroExport, MaestroPages } from "app/shared/models";
import { Language } from "app/shared/models/language";
import { saveAs } from "file-saver";
import * as JsZip from "jszip";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments";
import { LayoutService } from "../../global/layout/layout.service";
import { ToastService } from "../../global/toast/toast.service";

@Injectable()
export class ProjectExportService {
    selectedLanguage : Language;

    defaultLanguage : Language = this._languageService.getBasicLanguage();
    pagesPreview: BehaviorSubject<{ id: string; pageId: number; preview: SafeResourceUrl; index: number }[]> = new BehaviorSubject([]);

    constructor(private _http: HttpClient, private _sanitizer: DomSanitizer, private _layout: LayoutService, private _toastService: ToastService, private _translateService: TranslateService, private _languageService: LanguageService) {
        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
        });
    }

    /**
     * Get an export by id
     * @param id
     * @returns
     */
    getById(id: number): Observable<MaestroExport> {
        return this._http.get(`${environment.projectsUrl}/export/${id}.json`).pipe(map((data: any) => <MaestroExport>data.data));
    }

    /**
     * Launch a generation
     *
     * @param id
     * @returns
     */
    generate(page: number, generationType: number = 0, versionExport?: number, pageDuplicateId?: number): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/generate.json`, { generationType: generationType, versionExport: versionExport, page: page, pageDuplicateId: pageDuplicateId, language: this.selectedLanguage ? this.selectedLanguage.internationalCode : null, defaultLanguage: this.defaultLanguage ? this.defaultLanguage.internationalCode : null });
    }

    checkAutoGenerate(page: string, pageDuplicateId?: number) {
        return this._http.post(`${environment.projectsUrl}/check_auto_generation.json`, { page: page, pageDuplicateId: pageDuplicateId });    }

    /**
     * Launch an HTML generation
     *
     * @param id
     * @returns
     */
    generateHTML(page: number, pageDuplicateId?: number): Observable<any> {
        const generationType = 0;
        return this._http.post(`${environment.projectsUrl}/generate.json`, { generationType, pageDuplicateId, page, language: this.selectedLanguage ? this.selectedLanguage.internationalCode : null, defaultLanguage: this.selectedLanguage ? this.selectedLanguage.isBasic : true  });
    }

    /**
     * Download HTML
     *
     * @param id
     * @returns
     */
    downloadHTML(id: string, pageDuplicateId?: number): void {
        this._http.post(`${environment.projectsUrl}/page/option/${id}​/download.json`, { pageDuplicateId }).subscribe((res: any) => {
            this._layout.spinner.show();
            if (res.type === "success") {
                if (!res.data.files) {
                    this._toastService.show({ message: this._translateService.instant("projects.export.no_files"), type: "info" });
                    this._layout.spinner.hide();
                    return;
                }
                const zip = new JsZip();

                let filesAdded = 0;
                res.data.files.forEach((file) => {
                    zip.file(file.originalName, file.file, { base64: true });
                    filesAdded++;
                    // console.log(`Adding file to archive: ${file.originalName} (${filesAdded}/${res.data.files.length})`);

                    if (filesAdded === res.data.files.length) {
                        this._toastService.show({ message: this._translateService.instant("projects.export.pending_download"), type: "info" });
                        zip.generateAsync({ type: "blob" }).then((content) => {
                            // console.log('Downloading archive..');
                            saveAs(content, `${res.data.name}.zip`);
                            this._layout.spinner.hide();
                        });
                    }
                });
            }
        });
    }

    downloadXml(pageId: Number, idPageDuplicate?: Number): void {
        this._http.post(`${environment.projectsUrl}/page/${pageId}/download/xml.json`, { pageDuplace: idPageDuplicate }).subscribe((data: any) => {
            const base64Data = data.data.content;
            const fileData = this.base64ToBlob(base64Data);
            saveAs(fileData, data.data.name);
        });
    }

    /**
     * Get preview for a page
     *
     * @param pageOption
     * @param fileType
     * @param generationType
     * @param version
     * @returns
     */
    getPreview(pageOption: string, fileType: string, generationType: number, pageDuplicateId?: number): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/page/option/${pageOption}/preview.json`, { fileType, generationType, pageDuplicateId });
    }

    /**
     * Get preview for many page's page options (different from getAllPreviews method)
     *
     * @param data array of pageOptionId: string, fileType: string, generationType: number, pageDuplicateId?: number
     * @returns
     */
    getMultiplePageOptionPreview(data): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/page/option/${null}/preview.json`, { data });
    }

    /**
     * Get all previews for all pages
     *
     * @param pageOption
     * @param fileType
     * @param generationType
     * @param version
     * @returns
     */
    getAllPreviews(pages): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/page/previews.json`, { pages });
    }

    getTemplateByElement(pageId: number, elementId: number, exportId: number) {
        return this._http.post(`${environment.projectsUrl}/element_gab_value.json`, { pageId: pageId, elementId: elementId, exportId: exportId });
    }

    /**
     * Get previews for a flatplan group by page option
     * @param pages
     * @param version
     */
    async preparePreview(pages: MaestroPages, version?: number) {
        const previews: { id: string; pageId: number; preview: SafeResourceUrl; index: number }[] = [];
        let pagePreviewToGet = [];

        pages.forEach((page) => {
            previews.push({ id: page.group, pageId: page.id, preview: null, index: page.index });
            const pageDuplicateId = page.pageDuplicateId ? page.pageDuplicateId : null;

            if (page.exist) {
                pagePreviewToGet.push({ pageOptionId: page.group, fileType: "jpg", generationType: 0, pageDuplicateId: pageDuplicateId });
            }
        });

        if (pagePreviewToGet.length) {


            await this.setPreview(previews, pagePreviewToGet);
        }
    }


    async setPreview(previews, pagePreviewToGet) : Promise<void>    {
        try {
            const response: any = await this.getMultiplePageOptionPreview(pagePreviewToGet).toPromise();

            Object.entries(response.data).forEach(([key, value]) => {
              const prev = previews.filter(p => p.id === key);

              prev.forEach(preview => {

                if (preview && Array.isArray(value)) {
                  preview.preview = this._sanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + value[preview.index]);
                }

              });
            });

            this.pagesPreview.next(previews);
          } catch (error) {
            console.error('Error fetching page previews', error);
            this.pagesPreview.next(previews);
          }
    }

    /**
     * Clear previews
     */
    clearPreviews(): void {
        this.pagesPreview.next([]);
    }

    createBlobUrl(data: string, header: string) {
        try {
            const byteCharacters = atob(data); // Decode a Base64-encoded string into a new string with a character for each byte of the binary data.
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i); // Create an array of byte values
            }

            const byteArray = new Uint8Array(byteNumbers); // Convert this array of byte values into a real typed byte array

            // Converted to a BLOB by wrapping it in an array
            const blob = new Blob([byteArray], { type: header });

            return URL.createObjectURL(blob);
        } catch (error) {
            this._toastService.show({ message: this._translateService.instant("projects.export.generation.noPreviewToDisplay"), type: "danger" });
            return null;
        }
    }

    sanitize(data: string) {
        return this._sanitizer.bypassSecurityTrustResourceUrl(data);
    }

    saveVersion(exportId: number, version: any): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/export/${exportId}/version.json`, version);
    }

    readVersion($versionId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/version/${$versionId}.json`);
    }

    deleteVersion($versionId: number): Observable<any> {
        return this._http.delete(`${environment.projectsUrl}/version/${$versionId}.json`);
    }

    reInitCover(exportId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/export/${exportId}/cover.json`);
    }

    base64ToBlob(base64Data: string): Blob {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: "application/octet-stream" });
        return blob;
    }
}
