import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faChevronCircleDown, faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { Language } from "app/shared/models/language";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";

@Component({
    selector: "app-edit-list-multiple",
    templateUrl: "./edit-list-multiple.component.html",
    styleUrls: ['./edit-list-mulitple.component.scss']
})
export class EditListMultipleComponent implements OnInit {
    @Input() field: any;
    @Output() saveList = new EventEmitter<any>();
    @Input() disabled: boolean;
    @Input() variableProjectId: number
    selectedLanguage : Language;
    basicLanguage : Language;

    values: any = [];
    dropdownList = [];
    protected readonly faChevronCircleUp = faChevronCircleUp;
    protected readonly faChevronCircleDown = faChevronCircleDown;
    displaySortableStates: any[] = [];


    readonly dropdownListSettings: IDropdownSettings = {
        singleSelection: false,
        allowSearchFilter: true,
        enableCheckAll: false,
        idField: "id",
        textField: "name",
        selectAllText: this._translate.instant("general.select_all"),
        unSelectAllText: this._translate.instant("general.unselet_all"),

    };

    constructor(private _translate: TranslateService,
        private _languageService: LanguageService) {
    }

    ngOnInit() {
        
        this.basicLanguage = this._languageService.getBasicLanguage();
        this._languageService.selectedLanguage$.subscribe((language) => {
            let listValue = this.field.listValue;
            let value = this.field.value;
            this.selectedLanguage = language
            
            if(!language.isBasic){
                
                listValue = [];
                
                for (let index = 0; index < this.field.listValueTranslations.length; index++) {
                    const valTranslation = this.field.listValueTranslations[index];
                    if(valTranslation[language.internationalCode]){
                        listValue.push(valTranslation[language.internationalCode]);
                    } else {
                        listValue.push(valTranslation[this.basicLanguage.internationalCode]);
                    }
                }

                value = [];

                
                this.field.value.forEach((val) => {

                    let indexListValue = this.field.listValueTranslations.findIndex((listVal) => listVal[this.basicLanguage.internationalCode] == val);
                    
                    if(indexListValue >= 0){
                        let valTranslation = this.field.listValueTranslations[indexListValue][this.selectedLanguage.internationalCode] ? this.field.listValueTranslations[indexListValue][this.selectedLanguage.internationalCode] : this.field.listValue[indexListValue];
                        value.push(valTranslation);
                    }
                })


                // value = this.field.valueTranslations.map((valTranslation) =>{
                //     return valTranslation[language.internationalCode];
                // })
            }

            if (value !== null && value.length > 0) {
                this.values = this.initItemList(value);
            }

            this.dropdownList = this.initItemList(listValue).sort((a, b) => {
                let nameA = a.name.toUpperCase();
                let nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        });
    }

    private initItemList(value) {
        const items = Array.isArray(value) ? value : [];
        let values = [];
        items.forEach(item => {
            if (typeof item === 'object') {
                values.push(item);
            } else {
                values.push({id: item, name: item})
            }
        })
        
        return values;
    }


    readonly sortablejsOptionsItems = {
        multiDrag: false, // Enable multi-drag
        selectedClass: 'selected',
        fallbackTolerance: 3,
        animation: 150,
        filter: ".not-sortable",
        draggable: ".item-selection",
        onEnd: (event: any) => {
            function array_move(arr: any[], oldIndex: number, newIndex: number) {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
                return arr;
            }

            const from = event.oldIndex;
            const to = event.newIndex;
            if (from != to) {
                let newTab = array_move(this.values, from, to);

                this.values = [];
                newTab.forEach(item => {
                    this.values.push(item);
                })
                this.saveList.emit(this.values)
            }

        }
    }

    deleteListValue(inputName: string): void {
        const newPosition = this.values.filter((value: { id: string, name: string }): boolean => value.name !== inputName);

        this.values = newPosition;
        this.saveList.emit(this.values)
    }

    displaySortable(variableProjectId: number): void {

        if (!this.displaySortableStates[variableProjectId]) {
            this.displaySortableStates[variableProjectId] = [];
            this.displaySortableStates[variableProjectId] = false;
        }

        this.displaySortableStates[variableProjectId] = !this.displaySortableStates[variableProjectId];
    }
    
    saveValueList(values){
        
        let value = [];
        if(this.selectedLanguage.isBasic){
            values.forEach((val) => {
                let indexListValue = this.field.listValue.findIndex((listVal) => listVal == val.id);
                
                if(indexListValue >= 0){
                    value.push(this.field.listValue[indexListValue]);
                }
            })
 
        } else {

            values.forEach((val) => {
                let indexListValue = this.field.listValueTranslations.findIndex((listVal) => listVal[this.selectedLanguage.internationalCode] == val.id);

                if(indexListValue >= 0){
                    value.push(this.field.listValueTranslations[indexListValue][this.basicLanguage.internationalCode]);
                }
            });
        }
        
        this.saveList.emit(value);
    }
}
