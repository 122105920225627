export class ExportElement {
    id: number;
    name: string;
    values: ExportElementValue[];
    medias: ExportElementMedia[];
    options: any;
}

export class ExportElementMedia {
    mediaId: number;
    position: number;
    name: string;
    width: number;
    height: number;
    positionPhotoWidth: number;
    positionPhotoHeight: number;
    x: number;
    y: number;
    rotate: number;
    exportMediaId: number;
    flipHorizontal: boolean;
    flipVertical: boolean;
    thumb: string;
    variableProjectId: number | null;
    copyright: string | null;
    legend: string | null;
    dateValid: string | null;
    isExpired: boolean | null;
    linkData: any | null;
}

export class ExportElementValue {
    key: string;
    value: any;
    valueTranslations: {};
    fieldType: string;
    isLoading: boolean;
    countRequest: number;
    valuePimId: number;
    listValue?: string[];
    valueProjectId: number;
    elemFieldId: number;
    elemTypeFieldId: number;
    productId: number;
    variableProjectId: number;
}
