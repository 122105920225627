export const MAESTRO_ROUTES = {
    public: {
        base: "",
        forbidden: "forbidden",
        login: "login",
        privateBase: "",
        password: {
            base: "password",
            change: "change",
            forgot: "forgot",
        },
    },
    pim: {
        base: "pim",
        element: "element",
        elementType: "element_type",
        list: "list",
        fieldset: "fieldset",
        tag: "tag",
        listcascade: "listcascade"
    },
    dam: {
        base: "dam",
        media: "media",
        list: "thumbnails",
        createForm: "create",
        editForm: "edit",
        tag: "tag",
        elementType: "datamodel",
    },
    upload: {
        base: "upload",
    },
    projects: {
        base: "projects",
        list: "list",
        templates: "templates",
        twig: "twig",
        variables: "variables",
        /** need refacto below */
        configure: "configure",
        general: "general",
        steps: {
            exports: "step-3",
            elements: "step-2",
            project: "step-1",
            template: "step-5",
            workflow: "step-4",
            flux: "step-6"
        },
        dashboard: "dashboard",
        export: "export",
        page: "page",
        html: "html",
        exportType: {
            flatplan: "flatplan",
            list: "list",
            pages: "pages",
            elements: "elements",
            social: {
                social: "social",
            },
            web: "web",
            marketing: "marketing",
            booklet: "booklet"
        },
        version: "version"
    },
    admin: {
        base: "admin",
        users: "users",
        roles: "roles",
        teams: "teams",
        languages: "languages",
        group: "groups",
        workflow: {
            base: "workflow",
            dam: "dam",
            flatplan: "flatplan",
            pim: "pim",
            project: "project",
        },
        interfaces: {
            base: "interfaces",
            socialnetworks: "socialnetworks",
            custom: "custom",
        },
    },
    actions: {
        view: "view",
        create: "create",
        update: "update",
        export: "export",
        media: "media",
    },
    params: {
        search: "search",
        type: "elementType",
        tag: "tag",
    },
    dashboard: {
        base: "dashboard",
    },
};
