import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MediaType } from "app/shared/helpers/media-type.helper";
import { MaestroElement, MaestroElements, MaestroMedias, Tab, Tabs } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { Element, ElementResources } from "app/shared/models/pim/element-resources.model";
import { saveAs } from "file-saver";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments";
import { AuthService } from "../admin/auth/auth.service";
import { SwalModalService } from "../global/modal/modal.service";
import { TagService } from "../tag/tag.service";
import { WorkflowService } from "../workflow/workflow.service";
import { ElementTypeService } from "./element-type.service";

@Injectable()
export class ElementService {
    filterTypes: BehaviorSubject<{ id: number; name: string }[]> = new BehaviorSubject([]);
    filterTags: BehaviorSubject<{ id: number; name: string }[]> = new BehaviorSubject([]);
    filterVersions: BehaviorSubject<{ id: number; name: string }[]> = new BehaviorSubject([]);
    filterSalabilityIndicator: BehaviorSubject<string> = new BehaviorSubject("");
    filterMediaSearch: BehaviorSubject<string> = new BehaviorSubject("");

    currentTab = new BehaviorSubject<Tab>(null);
    currentElement = new BehaviorSubject<Element>(null);
    currentAuth = new BehaviorSubject<boolean>(false);

    acl: ACL;

    constructor(
        private _translate: TranslateService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _modalService: SwalModalService,
        private _elementTypeService: ElementTypeService,
        private _http: HttpClient,
        private _workflowService: WorkflowService,
        private _tagService: TagService,
        private _authService: AuthService
    ) {
        this.acl = this._authService.getUserAclFromToken();
    }

    /**
     * Create element(s)
     * @param names
     * @param id
     * @param openFirst
     */
    create(names, id, openFirst = false): Observable<any> {
        return this._http.post<{ id: number }>(`${environment.pimUrl}/element/create.json`, {
            names: names,
            type: id,
            openFirst: openFirst,
        });
    }

    /**
     * Get elements
     * @returns
     */
    getElements(): Observable<any> {
        return this._http.get(`${environment.pimUrl}/element.json`);
    }

    /**
     * Get element id
     * @param id
     * @returns
     */
    getElmentIdPim(id: number): Observable<MaestroElements> {
        return this._http.get<MaestroElements>(`${environment.pimUrl}/element/get_id_pim/${id}.json`);
    }

    /**
     * Get element
     * @param id
     * @returns
     */
    getElement(id: number): Observable<any> {
        return this._http.get(`${environment.pimUrl}/element/${id}.json`);
    }

    /**
     * Get tabs for an element
     * @param id
     * @returns
     */
    getElementTabs(id: number): Observable<Tabs> {
        return this._http.get<Tabs>(`${environment.pimUrl}/element/${id}/tabs.json`);
    }

    /**
     * Update a field
     * @param elementId
     * @param body
     * @returns
     */
    updateField(elementId: number, body: any): Observable<any> {
        return this._http.post(`${environment.pimUrl}/element/${elementId}/field.json`, body);
    }

    /**
     * Get linked medias for an element
     * @param elementId
     * @returns
     */
    getMedias(elementId: number): Observable<MaestroMedias> {
        return this._http.get<MaestroMedias>(`${environment.pimUrl}/element/${elementId}/medias.json`);
    }

    /**
     * Get linked medias for an element value
     * @param elementValueId
     * @param inputId
     * @param collectionIndex
     * @returns
     */
    getElementValueMedias(elementValueId: number, collectionIndex: number = -1, inputId: number = -1): Observable<MaestroMedias> {
        return this._http.post<MaestroMedias>(`${environment.pimUrl}/element_value/${elementValueId}/medias.json`, { collectionIndex: collectionIndex, inputId: inputId });
    }

    // /**
    //  * Get linked medias for an element
    //  * @param elementId
    //  * @returns
    //  */
    // getMediasId(elementId: number): Observable<any> {
    //     return this._http.get<MaestroMedias>(`${environment.pimUrl}/element/${elementId}/medias/id.json`);
    // }

    /**
     * Link medias to an element
     *
     * @param mediasData
     * @param id
     * @param forElementMedia
     * @param inputId
     * @param collectionIndex
     * @returns
     */
    saveMedias(mediasData: any, id: number, forElementMedia: boolean = true, collectionIndex: number = -1, inputId: number = -1): Observable<MaestroMedias> {
        return this._http.put<MaestroMedias>(`${environment.pimUrl}/${forElementMedia ? "element" : "element_value"}/${id}/media.json`, {
            mediasData: mediasData,
            collectionIndex: collectionIndex,
            inputId: inputId,
        });
    }

    /**
     * Delete an element
     * @param elementId
     * @returns
     */
    deleteElement(elementId: number): Observable<{ id: number }> {
        return this._http.delete<any>(`${environment.pimUrl}/element/${elementId}.json`);
    }

    /**
     * Get tab by id
     * @param tabId
     * @returns
     */
    getTabById(tabId: number): Observable<Tab> {
        return this._http.get<Tab>(`${environment.pimUrl}/tab/${tabId}.json`);
    }

    /**
     * Change the name of an element
     * @param id
     * @param name
     * @param nameTranslations
     * @returns
     */
    changeName(id: number, name: string, nameTranslations: object): Observable<any> {
        return this._http.post(`${environment.pimUrl}/element/${id}/name.json`, { name: name, nameTranslations: nameTranslations });
    }

    /**
     * Change collection's order
     * @param collectionIds
     * @returns
     */
    setPositionColletion(collectionIds: number[], elementFieldId: number) {
        return this._http.post(`${environment.pimUrl}/element/position_collection.json`, { collection_ids: collectionIds, elementFieldId: elementFieldId });
    }

    /**
     * Get resources for an element
     * @param id
     * @returns
     */
    getElementResources(id: number): Observable<ElementResources> {
        this._tagService.setModulePath("pim");

        return forkJoin([
            // Workflow
            this._workflowService.display(environment.pimUrl, "element", id).pipe(map((r: any) => r.data)),
            this._workflowService.getCurrentPlace(environment.pimUrl, "element", id).pipe(map((r: any) => r.data)),
            this._workflowService.getUserGrantSetValue(environment.pimUrl, "element", id).pipe(map((r: any) => r.data)),
            // Tags
            this._tagService.getEntityTags(id).pipe(map((r) => r.data)),
            // Versions
            // this._versionService.getVersions().pipe(map((r) => r.data.list)),
            // this._versionService.getVersionsByElement(id).pipe(map((r) => r.data.list)),
            // this._versionService.getVersionValid(id).pipe(map((r) => r.data.list)),
            // Element
            this.getElement(id).pipe(map((r) => r.data)),
        ]).pipe(
            map(
                (data: any) =>
                    <ElementResources>{
                        workflow: {
                            display: data[0].display,
                            transitions: data[0].transitions,
                            currentPlace: data[1].place,
                            isLastPlace: data[1].last,
                            authoSetValue: data[2],
                        },
                        tags: data[3],
                        // versions: {
                        //     allVersions: data[4],
                        //     elementVersions: data[5],
                        //     validVersions: data[6],
                        // },
                        element: data[4],
                    }
            )
        );
    }

    /**
     * Get sidebar data
     * @returns
     */
    getSidebarData(): Observable<any> {
        return forkJoin([this._tagService.getSidebarTags(), this._elementTypeService.getSidebarTypes()]).pipe(
            map((data) => {
                return <any>{
                    tags: data[0].data,
                    types: data[1].data,
                };
            })
        );
    }

    /**
     * Get the preview of an element
     * @param elementId
     * @returns
     */
    getElementPreview(elementId: number): Observable<MaestroElement> {
        return this._http.get(`${environment.pimUrl}/element/${elementId}/preview.json`);
    }

    duplicateElement(elementId: number): Observable<any> {
        return this._http.get(`${environment.pimUrl}/element/${elementId}/duplicate.json`);
    }

    getStory(elementId: number, start: number): Observable<any> {
        return this._http.post(`${environment.pimUrl}/element/${elementId}/story.json`, { start: start });
    }

    getDatatableElements(filter: any): Observable<any> {
        return this._http.post(`${environment.pimUrl}/datatable/element.json`, filter);
    }

    duplicateFieldsetData(elementId: number, body: any): Observable<any> {
        return this._http.get(`${environment.pimUrl}/element/${elementId}/duplicate/data/field/${body.source}/into/field/${body.target}.json`);
    }

    importFromFile(file: any) {
        return this._http.post(`${environment.pimUrl}/import/element.json`, file);
    }

    /**
     * Change medias data structure
     * @param adding
     * @param elementMedias
     * @param selectedMedias
     * @param mediasModal
     * @returns
     */
    manageMedia(adding: boolean, elementMedias: any, selectedMedias: any = [], mediasModal: any = []) {
        let mergedMedias = [];

        elementMedias.forEach((media) => {
            // let type = media.extension ? Object.keys(MediaType).find((key) => MediaType[key] === Object.values(MediaType).find((value) => value.indexOf(media.extension) != -1)) : media.type;

            mergedMedias.push({
                id: media.id,
                type: media.type,
                position: media.position,
                link: media.thumbPath ? media.thumbPath : media.link ? media.link : null,
                loadMetadata: media.loadMetadata,
            });
        });

        if (adding) {
            selectedMedias.forEach((id) => {
                let mediaModel = mediasModal.find((model) => model.id === id);

                if (undefined !== mediaModel) {
                    let type = Object.keys(MediaType).find((key) => MediaType[key] === Object.values(MediaType).find((value) => value.indexOf(mediaModel.extension) != -1));

                    mergedMedias.push({
                        id: id,
                        type: type,
                        position: null,
                        link: mediaModel.thumbPath ? mediaModel.thumbPath : null,
                        loadMetadata: mediaModel.loadMetadata ? mediaModel.loadMetadata : null,
                    });
                }
            });
        }

        Object.keys(MediaType).forEach((key) => {
            let tmp = mergedMedias.filter((m) => m.type === key);

            for (let i = 0; i < tmp.length; i++) {
                tmp[i].position = i + 1;
            }
        });

        return mergedMedias;
    }

    exportPdf(elementIds: number[]): Observable<any> {
        return this._http.post(`${environment.pimUrl}/element/html/template.json`, { elementIds: elementIds });
    }

    downloadPdf(data) {
        // Base64 data to ByteArray (binary string to ASCII chars [= byte array]) to Blob
        const byteArray = new Uint8Array(
            atob(data.blob)
                .split("")
                .map((char) => char.charCodeAt(0))
        );

        const blob = new Blob([byteArray], { type: "application/pdf" });

        saveAs(blob, data.filename);
    }
}
