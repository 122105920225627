import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { faFileImage, faFilePdf, faFileWord } from "@fortawesome/free-regular-svg-icons";
import { faArrowsAlt, faCaretDown, faCaretRight, faCog, faDownload, faExpand, faFont, faSyncAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { ExportPageService } from "app/core/services/project/export/export-page-view.service";
import { ProjectExportService } from "app/core/services/project/export/export.service";
import { ThumbnailsService } from "app/core/services/thumbnails/thumbnails.service";
import { WorkflowData } from "app/shared/components/workflow";
import { MaestroPage, Tabs } from "app/shared/models";
import { ExportElement, ExportElementMedia } from "app/shared/models/project/export-element.model";
import { MAESTRO_ROUTES } from "app/shared/routes/routes";
import { Subscription } from "rxjs";
import { environment } from "src/environments";
import { LanguageService } from "app/core/services/admin/language/language.service";

@Component({
    selector: "app-export-page-view",
    templateUrl: "./export-page-view.component.html",
    styleUrls: ["./export-page-view.component.scss"],
    animations: [
        trigger("hidePreview", [
            state(
                "closed",
                style({
                    display: "none",
                    transform: "translateX(1000px)",
                    opacity: 0,
                })
            ),
            transition("open => *", [animate("0.3s ease-in-out")]),
            transition("closed => open", [animate("0.3s ease-in")]),
        ]),
        trigger("slideIn", [
            state(
                "slideClose",
                style({
                    flex: "0 0 100%",
                    maxWidth: "100%",
                })
            ),
            transition("slideOpen <=> slideClose", animate(".4s ease-in-out")),
        ]),
        trigger("alert", [
            state(
                "alertClose",
                style({
                    flex: "0 0 100%",
                    maxWidth: "100%",
                })
            ),
            transition("alertOpen => *", animate("0.9s ease-in-out")),
            transition("alertClose => alertOpen", animate("0.3s ease-in-out")),
        ]),
    ],
})
export class ExportPageViewComponent implements OnInit, OnDestroy {
    preview;

    private _page: MaestroPage;

    public products: ExportElement[];

    readonly faCog = faCog;
    readonly faFileImage = faFileImage;
    readonly faFilePdf = faFilePdf;
    readonly faFileWord = faFileWord;
    readonly faFont = faFont;
    readonly faSyncAlt = faSyncAlt;
    readonly faDownload = faDownload;
    readonly faExpand = faExpand;
    readonly faTimes = faTimes;
    readonly faArrowsAlt = faArrowsAlt;
    readonly faCaretRight = faCaretRight;
    readonly faCaretDown = faCaretDown;
    page: any;

    previewUrl: SafeResourceUrl;

    blockTexts = false;
    blockPictures = false;
    activeNav = "options";

    private _pageId: number;
    private _exportId: number;
    private _projectId: number;
    version: number | null;
    pageDuplicateId: number | null;

    private tabs: Tabs;

    display: WorkflowData;
    medias: [];
    places: [];
    options: [];
    private _actionButtonSub: Subscription;

    selectedProduct: ExportElement;
    showArrowTemplate: boolean = true;
    sortableProducts: ExportElement[] = [];
    noSortableProducts: ExportElement[] = [];
    showArrowProduct: number = 0;
    isOpen: boolean = true;
    animationState: string = "inactive";
    isAdmin: boolean = false;

    showPreview = false;

    exportType: string;

    previewExtension: string;
    header: string;

    fullscreenPreview: boolean = false;

    private mercureHubGenerationTopic: string = "http://maestro/generation/preview/pdf";
    private eventSource = new EventSource(environment.mercureHubUrl + "?topic=" + encodeURIComponent(this.mercureHubGenerationTopic));
    showSpinner: boolean = false;
    private blobUrl: string;

    hidePreviewState = "closed";
    alertState = "alertClose";

    readonly sortableJSOptions = {
        ghostClass: "placeholder",
        handle: ".sortable",
        selectedClass: "selected", // The class applied to the selected items
        onEnd: function (event: any) {
            function array_move(arr: any[], oldIndex: number, newIndex: number): void {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            }
            const from = event.oldIndex;
            const to = event.newIndex;

            array_move(this.sortableProducts, from, to);

            let arrayId = this.sortableProducts.map((product) => product.id).filter((id) => id !== null);
            this._updateTabsOrder(arrayId);
        }.bind(this),
    };

    constructor(
        private _layout: LayoutService,
        private _service: ExportPageService,
        private _route: ActivatedRoute,
        private _exportService: ProjectExportService,
        private _translate: TranslateService,
        private _toaster: ToastService,
        private _spinner: SpinnerService,
        private _swalModalService: SwalModalService,
        private _userService: UserService,
        private _languageService:LanguageService,
        private _thumbnailService: ThumbnailsService
    ) {}

    ngOnInit(): void {
        const resources = this._route.snapshot.data.resources;
        this._pageId = this._route.snapshot.params.id;
        this._projectId = this._route.parent.parent.parent.parent.parent.parent.snapshot.params.id;
        this._exportId = this._route.parent.parent.parent.snapshot.params.id;

        this.products = resources.templateByElement;
        this.version = this._route.queryParams["_value"]["version"] ? this._route.queryParams["_value"]["version"] : null;
        this.pageDuplicateId = this._route.queryParams["_value"]["duplicate"] ? this._route.queryParams["_value"]["duplicate"] : null;
        this._page = resources;
        this.page = resources.page;

        this.exportType = this._route.parent.parent.parent.snapshot.data.export.type;

        if (this.exportType === "CDF" || this.exportType === "Marketing" || this.exportType === "BOOKLET") {
            this.previewExtension = "pdf";
            this.header = "application/pdf";
        } else if (this.exportType === "HTML") {
            this.previewExtension = "html";
            this.header = "text/html";
        }

        this._exportService.getPreview(this.page.pageOptionId, this.previewExtension, 0, this.pageDuplicateId).subscribe((response: any) => {
            if (undefined !== response.data[this.page.pageOptionId] && Array.isArray(response.data[this.page.pageOptionId])) {
                this.showGeneratedPreview(response.data[this.page.pageOptionId][0], this.header);
            }
        });

        // Get workflow for this page
        this.getWorkflow();

        this.tabs = [];

        this.products.forEach((product) => {
            this.tabs.push({
                id: product.id,
                name: product.name,
            });

            product.medias.forEach((media) => {
                this.setMetadataLink(media);
            });
        });

        this.selectedProduct = this.products[0];
        this._layout.actionButton.enable = true;
        this._layout.actionButton.title = this.isOpen ? "hidePreview" : "displayPreview";
        this._actionButtonSub = this._layout.actionButton.click$.subscribe((_) => this.toogle());

        this._layout.breadcrumb.setPath({ routerLink: "", name: this.selectedProduct ? this.selectedProduct.name : "" }, 3);
        const localUser = this._userService.getUserIdFromToken();
        if (!this._layout.breadcrumb.getPath()[2]) {
            let path = `/${MAESTRO_ROUTES.projects.base}/${this._projectId}/${MAESTRO_ROUTES.projects.export}/${this._exportId}/`;

            switch (this.exportType) {
                case "CDF":
                    path += "flatplan";
                    break;
                case "Marketing":
                    path += "marketing";
                    break;
                case "BOOKLET":
                    path += "booklet";
                    break;
                case "HTML":
                    path += "html";
                    break;
            }

            if (this.version) {
                path += "?version=" + this.version;
            }

            this._layout.breadcrumb.setPath({
                name: this._route.parent.parent.parent.snapshot.data.export.name,
                routerLink: path
            }, 2)
            this._languageService.enable = true;
        }

        // Will be called every time an update is published by the server
        // @TODO: Add Mercure's private update to avoid this tricky control
        this.eventSource.onmessage = (event) => {
            let data = JSON.parse(event.data);
            
            this.showSpinner = false;
            if (data.hasOwnProperty("projectId") && data.projectId === +this._projectId) {
                if (data.hasOwnProperty("exportId") && data.exportId === +this._exportId) {
                    if (data.hasOwnProperty("pageId") && data.pageId === +this._pageId) {
                        if (data.hasOwnProperty("version") && data.version == this.version) {
                            if (data.hasOwnProperty("pageDuplicateId") && data.pageDuplicateId == this.pageDuplicateId) {
                                if (data.hasOwnProperty("preview") && data.preview) {
                                    this._toaster.show({ message: this._translate.instant("mercure.preview.success"), type: "info" });

                                    this.showGeneratedPreview(data.preview, this.header);
                                } else if (data.hasOwnProperty("error") && data.error) {
                                    if (data.hasOwnProperty("userId") && localUser === data.userId) {
                                        this._toaster.show({ message: this._translate.instant("mercure.preview.error", { error: data.error }), type: "danger" });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        };
        this._userService.getById(localUser).subscribe((data) => {
            if (data.data.internalCode == "ADMIN") {
                this.isAdmin = true;
            }
        });

        this.navActive();
        this.filterSortable();
        this.filterNoSortable();
    }

    ngOnDestroy() {
        URL.revokeObjectURL(this.blobUrl); // For memory management;
        this._layout.breadcrumb.setPath(null, 3);
        this._layout.sidebar.sideBarDef = null;
        this._layout.sidebar.enable = false;
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._actionButtonSub.unsubscribe();
        this.eventSource.close();
        this._languageService.enable = false;
    }

    filterSortable() {
        this.sortableProducts = this.products.filter((product) => product.id !== null);
    }

    filterNoSortable() {
        this.noSortableProducts = this.products.filter((product) => product.id === null);
        if (this.noSortableProducts.length) {
            this.showArrowProduct = -1;
        }
    }

    /**
     * Update tabs order
     * @param tabsId
     */
    private _updateTabsOrder(tabsId: number[]): void {
        const data = [];

        tabsId.forEach((id) => {
            const d = this.tabs.filter((t) => t.id === Number(id))[0];
            data.push(d);
        });
        this._service.updateTabsOrder(this._pageId, data).subscribe();
    }

    /**
     * Validate a workflow step
     *
     * @param key
     */
    validWorkflow(event: any): void {
        this._service.validWorkflow({ object_id: this._pageId, transition: event.key, note: event.note, pageDuplicateId: this.pageDuplicateId }).subscribe(() => {
            this.getWorkflow();
        });
    }

    toogle() {
        this.isOpen = !this.isOpen;
        this._layout.actionButton.title = this.isOpen ? "hidePreview" : "displayPreview";
    }
    /**
     * Update a position media
     * @param data
     */
    updateMedia(data: any, element: any): void {
        const media = data[0];
        const place: ExportElementMedia = data[1];
        const isCrop = data[2];

        const mediaId = !isCrop && media ? media.id : place.mediaId;
        const exportId = this._route.parent.parent.parent.snapshot.params.id;
        const x = place.x;
        const y = place.y;
        const widthMedia = !isCrop ? 0 : place.width;
        const heightMedia = !isCrop ? 0 : place.height;
        const rotateMedia = place.rotate;
        const flatplanPageId = this._route.snapshot.params.id;
        const templatePositionMedia = place.position;
        const flipHorizontal = place.flipHorizontal;
        const flipVertical = place.flipVertical;
        const id = place.exportMediaId;
        const variableProjectId = place.variableProjectId;
        let copyright = place.copyright ? place.copyright : "";
        let legend = place.legend ? place.legend : "";
        let dateValid = place.dateValid ? place.dateValid : "";

        let linkData = null;
        if ((-1 == mediaId || null == mediaId) && media.hasOwnProperty("type") && (media.hasOwnProperty("originalLink") || media.hasOwnProperty("link")) && media.hasOwnProperty("loadMetadata")) {
            linkData = {
                type: media.type,
                link: media.hasOwnProperty("originalLink") ? media.originalLink : media.hasOwnProperty("link") ? media.link : "",
                loadMetadata: media.loadMetadata,
            };
        }

        this._service
            .updateMedia({
                id,
                mediaId,
                exportId,
                x,
                y,
                widthMedia,
                heightMedia,
                rotateMedia,
                element,
                flatplanPageId,
                templatePositionMedia,
                flipHorizontal,
                flipVertical,
                variableProjectId,
                copyright,
                legend,
                dateValid,
                version: this.version,
                pageDuplicateId: this.pageDuplicateId,
                linkData: linkData,
            })
            .subscribe((m) => {
                place.thumb = m.data.thumb;
                place.exportMediaId = m.data.id;

                let productPlace = this.selectedProduct.medias.find((p) => {
                    return p.exportMediaId == place.exportMediaId;
                });

                productPlace.mediaId = m.data.mediaId;
                productPlace.linkData = m.data.linkData;
                this.setMetadataLink(productPlace);
            });
    }

    /**
     * Unlink a media
     *
     * @param id
     */
    deleteMedia(id: number): void {
        this._service.deleteMedia(id).subscribe((response) => {
            if (response.hasOwnProperty("type") && "success" === response.type) {
                //this.selectedProduct.medias.find(i => i.exportMediaId === id).exportMediaId = -1;
                let med = this.selectedProduct.medias.find((i) => i.exportMediaId === id);

                if (med.hasOwnProperty("linkData")) {
                    med.linkData = null;
                }
            }
        });
    }

    /**
     * Generate CDF preview
     */
    generatePreview(definition: number = 0): void {
        let mainThis = this;
        this._spinner.disable();
        this.showSpinner = true;

        this._exportService.generate(this._pageId, definition, this.version, this.pageDuplicateId).subscribe({
            next(response) {
                if (response.hasOwnProperty("type") && "success" === response.type) {
                    mainThis._toaster.show({ message: mainThis._translate.instant("response.success.generation.loading"), type: "info" });
                }
            },
            error() {
                mainThis.showSpinner = false;
            },
        });

        this._spinner.activate();
    }

    /**
     * Generate HTML preview
     */
    generateHTML(): void {
        this._exportService.generateHTML(this._pageId, this.pageDuplicateId).subscribe((response) => {
            if (response.type === "success" && Array.isArray(response.data)) {
                this.showGeneratedPreview(response.data[0], this.header);
            }
        });
    }

    checkValidityMediaBeforeGenerate(typeGeneration, definition: number = 0) {
        let mediaExpire = this.selectedProduct.medias.findIndex((media) => {
            return media.isExpired;
        });

        if (mediaExpire >= 0) {
            this._swalModalService
                .confirm({
                    showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    title: "Attention",
                    text: "Des médias sont expirés êtes-vous sûr de vouloir continuer?",
                    confirmButtonText: this._translate.instant("modal.yes"),
                    cancelButtonText: this._translate.instant("modal.no"),
                })
                .then((generate) => {
                    if (generate.isConfirmed) {
                        if (typeGeneration == "html") {
                            this.generateHTML();
                        } else {
                            this.generatePreview(definition);
                        }
                    }
                });
        } else {
            if (typeGeneration == "html") {
                this.generateHTML();
            } else {
                this.generatePreview(definition);
            }
        }
    }

    /**
     * Download HTML
     */
    downloadHTML() {
        this._exportService.downloadHTML(this.page.pageOptionId, this.pageDuplicateId);
    }

    downloadXML() {
        this._exportService.downloadXml(this._pageId, this.pageDuplicateId);
    }

    showGeneratedPreview(data: string, header: string) {
        this.blobUrl = this._exportService.createBlobUrl(data, header);
        this.previewUrl = this._exportService.sanitize(this.blobUrl);
        this.showPreview = true;
    }

    getWorkflow() {
        this._service.getWorkflow(this._pageId).subscribe((data) => {
            let display = JSON.parse((data as any).data.display);

            let places = (data as any).data;

            this.blockPictures = places.blockMedia;
            this.blockTexts = places.blockText;

            this.display = {
                display: display.display,
                transitions: display.transitions,
                currentPlace: places.place,
                authoSetValue: (data as any).data.grantSetValue,
                isLastPlace: places.last,
            };
        });

        /*
            ATTENTION TODO : Suppression des workflow sur l'edition de la page. A remodifier quand on aura patch les workflows
        */

        // this.blockPictures = false;
        // this.blockTexts = false;

        // this.display = {
        //     display: [],
        //     transitions: [],
        //     currentPlace: "",
        //     authoSetValue: true,
        //     isLastPlace: null,
        // };
    }

    navActive() {
        if (this.selectedProduct === undefined) {
            return;
        } else {
            if (this.selectedProduct.values.length > 0) {
                this.activeNav = "text";
            } else if (this.selectedProduct.medias.length > 0) {
                this.activeNav = "medias";
            } else {
                this.activeNav = "options";
            }
        }
    }

    /**
     * Load link metadata and add property to display right image in card of child's component
     * @param media
     */
    setMetadataLink(media) {
        if (-1 == media.mediaId && null !== media.linkData && media.linkData.type == "image" && true == media.linkData.loadMetadata) {
            this._thumbnailService.getUrlMetadata(media.linkData.link).subscribe((metadata) => {
                if (metadata.thumbPath.length) {
                    media.linkData.metadataLink = metadata.thumbPath;
                }
            });
        }
    }
}
