import { Component, OnDestroy, OnInit } from "@angular/core";
import { faEdit, faGlobe, faImages, faMagic, faProjectDiagram, faShieldAlt, faTh, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SidebarClassicComponent } from "app/shared/components/sidebar";
import { ACL } from "app/shared/models/acl";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { activatedModules } from "src/environments/activated-modules";

@Component({
    template: "<router-outlet></router-outlet>",
})
export class AdminComponent implements OnInit, OnDestroy {
    readonly faUser = faUser;
    readonly faShieldAlt = faShieldAlt;
    readonly faGlobe = faGlobe;
    readonly faProjectDiagram = faProjectDiagram;
    readonly faImages = faImages;
    readonly faEdit = faEdit;
    readonly faMagic = faMagic;
    readonly faTh = faTh;
    readonly faUsers = faUsers;

    private acl: ACL;

    constructor(private _layout: LayoutService, private _userService: UserService) {}

    ngOnInit(): void {
        this.acl = this._userService.getUserAclFromToken();
        this._layout.breadcrumb.setPath({ routerLink: `/${MAESTRO_ROUTES.admin.base}`, name: "breadcrumb.admin.home" }, 0);

        this._initSideBar();
    }

    ngOnDestroy() {
        this._layout.breadcrumb.setPath(null, 0);
        this._layout.sidebar.enable = false;
        this._layout.sidebar.sideBarDef = null;
    }

    /**
     * Create the sidebar by adding each menu if authorizations are presents
     *
     * @returns
     */
    private createSidebarData(): any {
        const data = [];

        if (this.acl.MAESTRO_MODULE_ADMIN_MODULE && this.acl.SYSPAD_ADMIN_USER_LIST) {
            data.push({
                id: MAESTRO_ROUTES.admin.users,
                name: "users",
                icon: faUser,
            });
        }

        if (this.acl.MAESTRO_MODULE_ADMIN_MODULE && this.acl.MAESTRO_ADMIN_GROUP_LIST) {
            data.push({
                id: MAESTRO_ROUTES.admin.group,
                name: "groups",
                icon: faUsers,
            });
        }

        if (this.acl.MAESTRO_MODULE_ADMIN_MODULE && this.acl.SYSPAD_ADMIN_ROLE_LIST) {
            data.push({
                id: MAESTRO_ROUTES.admin.roles,
                name: "roles",
                icon: faShieldAlt,
            });
        }

        const workflow = {
            id: `${MAESTRO_ROUTES.admin.workflow.base}`,
            name: "workflows",
            icon: faProjectDiagram,
            sections: [],
        };

        if (this.acl.MAESTRO_ADMIN_WORKFLOW_LIST) {
            if (activatedModules.pim) {
                workflow.sections.push({
                    id: `${MAESTRO_ROUTES.admin.workflow.base}/${MAESTRO_ROUTES.admin.workflow.pim}`,
                    name: "pim",
                    icon: faEdit,
                });
            }

            if (activatedModules.dam) {
                workflow.sections.push({
                    id: `${MAESTRO_ROUTES.admin.workflow.base}/${MAESTRO_ROUTES.admin.workflow.dam}`,
                    name: "dam",
                    icon: faImages,
                });
            }

            if (activatedModules.project) {
                workflow.sections.push({
                    id: `${MAESTRO_ROUTES.admin.workflow.base}/${MAESTRO_ROUTES.admin.workflow.project}`,
                    name: "project",
                    icon: faMagic,
                });
            }
        }

        if (this.acl.MAESTRO_MODULE_ADMIN_MODULE && workflow.sections.length > 0) {
            data.push(workflow);
        }

        if (this.acl.MAESTRO_MODULE_ADMIN_MODULE && this.acl.MAESTRO_ADMIN_LANGUAGE_LIST) {
            data.push({
                id: MAESTRO_ROUTES.admin.languages,
                name: "languages",
                icon: faGlobe,
            });
        }

        return data;
    }

    /**
     * Init the sidebar
     */
    private _initSideBar() {
        this._layout.sidebar.enable = true;
        this._layout.sidebar.sideBarDef = {
            component: SidebarClassicComponent,
            options: <any>{
                absoluteRoute: "/" + MAESTRO_ROUTES.admin.base,
                data: this.createSidebarData(),
            },
        };
    }
}
